<template>
  <app-autocomplete
    filter
    :items="items"
    :label="$t('actionLog.action')"
    hide-details
    multiple
    dense
    solo
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { NAMESPACE } from '@/modules/Admin/store';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ActionTypeSelect',

  computed: {
    ...mapState(NAMESPACE, ['actionTypes']),

    items() {
      return Object.values(this.actionTypes ?? {}).sort();
    }
  },

  methods: mapActions(NAMESPACE, ['fetchActionTypes'])
};
</script>
