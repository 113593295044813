export default {
  computed: {
    actionTypeSelection: {
      get() {
        return this.getFilterQueryParam('actionType');
      },
      set(value) {
        this.setFilterQueryParam('actionType', value);
      }
    },
    citySelection: {
      get() {
        return this.getFilterQueryParam('city');
      },
      set(value) {
        this.setFilterQueryParam('city', value);
      }
    },
    creationDateSelection: {
      get() {
        return this.getFilterQueryParam('creationDate');
      },
      set(value) {
        this.setFilterQueryParam('creationDate', value);
      }
    },
    creditorNameSelection: {
      get() {
        return this.getFilterQueryParam('creditorName');
      },
      set(value) {
        this.setFilterQueryParam('creditorName', value);
      }
    },
    creditorSapNumberSelection: {
      get() {
        return this.getFilterQueryParam('creditorSapNumber');
      },
      set(value) {
        this.setFilterQueryParam('creditorSapNumber', value);
      }
    },
    debtorSapNumberSelection: {
      get() {
        return this.getFilterQueryParam('debtorSapNumber');
      },
      set(value) {
        this.setFilterQueryParam('debtorSapNumber', value);
      }
    },
    emailSelection: {
      get() {
        return this.getFilterQueryParam('email');
      },
      set(value) {
        this.setFilterQueryParam('email', value);
      }
    },
    productSelection: {
      get() {
        return this.getFilterQueryParam('product');
      },
      set(value) {
        this.setFilterQueryParam('product', value);
      }
    },
    sapRegisteredSelection: {
      get() {
        return this.getFilterQueryParam('sapRegistered');
      },
      set(value) {
        this.setFilterQueryParam('sapRegistered', value);
      }
    },
    setUpFeeChargedSelection: {
      get() {
        return this.getFilterQueryParam('setUpFeeCharged');
      },
      set(value) {
        this.setFilterQueryParam('setUpFeeCharged', value);
      }
    },
    transactionKeySelection: {
      get() {
        return this.getFilterQueryParam('transactionKey');
      },
      set(value) {
        this.setFilterQueryParam('transactionKey', value);
      }
    },
    userCreatedSelection: {
      get() {
        return this.getFilterQueryParam('userCreated');
      },
      set(value) {
        this.setFilterQueryParam('userCreated', value);
      }
    },
    userNameSelection: {
      get() {
        return this.getFilterQueryParam('userName');
      },
      set(value) {
        this.setFilterQueryParam('userName', value);
      }
    },
    voucherReferenceNumberSelection: {
      get() {
        return this.getFilterQueryParam('voucherReferenceNumber');
      },
      set(value) {
        this.setFilterQueryParam('voucherReferenceNumber', value);
      }
    },
    voucherSapNumberSelection: {
      get() {
        return this.getFilterQueryParam('voucherSapNumber');
      },
      set(value) {
        this.setFilterQueryParam('voucherSapNumber', value);
      }
    },
    hasActiveFilters() {
      return (
        [].concat(
          this.actionTypeSelection,
          this.citySelection,
          this.creationDateSelection,
          this.creditorNameSelection,
          this.creditorSapNumberSelection,
          this.debtorSapNumberSelection,
          this.emailSelection,
          this.productSelection,
          this.roleSelection,
          this.sapNumberSelection,
          this.sapRegisteredSelection,
          this.setUpFeeChargedSelection,
          this.statusSelection,
          this.transactionKeySelection,
          this.userCreatedSelection,
          this.userNameSelection,
          this.voucherReferenceNumberSelection,
          this.voucherSapNumberSelection
        ).length > 0
      );
    }
  }
};
