<template>
  <v-row dense>
    <v-col cols="12" sm="4" md="3" lg="2">
      <date-select v-model="creationDateSelection" label-icon="" />
    </v-col>
    <v-col cols="12" sm="4" md="3" lg="2">
      <action-type-select v-model="actionTypeSelection" />
    </v-col>
    <v-col cols="4" md="3" lg="2">
      <text-search-filter
        v-model="creditorSapNumberSelection"
        label="actionLog.creditorSapNumber"
      />
    </v-col>
    <v-col cols="4" md="3" lg="2">
      <text-search-filter v-model="debtorSapNumberSelection" label="actionLog.debtorSapNumber" />
    </v-col>
    <v-col cols="4" md="3" lg="2">
      <text-search-filter v-model="voucherSapNumberSelection" label="actionLog.voucherSapNumber" />
    </v-col>
    <v-col cols="4" md="3" lg="2">
      <text-search-filter
        v-model="voucherReferenceNumberSelection"
        label="actionLog.voucherReferenceNumber"
      />
    </v-col>
    <v-col cols="4" md="3" lg="2">
      <text-search-filter v-model="transactionKeySelection" label="actionLog.transactionKey" />
    </v-col>

    <v-col cols="12" class="pt-3 pb-4">
      <active-filter-chip
        v-for="(selection, index) in creationDateSelection"
        :key="'creationDate_' + selection"
        v-model="creationDateSelection"
        :index="index"
      >
        {{ $t('date') }}:
        <template v-if="!selection.start">{{ $t('to') }} {{ selection.end | formatDate }}</template>
        <template v-else-if="!selection.end">
          {{ $t('from') }} {{ selection.start | formatDate }}
        </template>
        <template v-else>
          {{ selection.start | formatDate }}
          <template v-if="selection.start !== selection.end">
            - {{ selection.end | formatDate }}
          </template>
        </template>
      </active-filter-chip>
      <active-filter-chip
        v-for="(selection, index) in creditorSapNumberSelection"
        :key="'creditorSapNumber_' + selection"
        v-model="creditorSapNumberSelection"
        :index="index"
      >
        {{ $t('actionLog.creditorSapNumber') }}: {{ selection }}
      </active-filter-chip>
      <active-filter-chip
        v-for="(selection, index) in actionTypeSelection"
        :key="'actionType_' + selection"
        v-model="actionTypeSelection"
        :index="index"
      >
        {{ $t('actionLog.action') }}: {{ selection }}
      </active-filter-chip>
      <active-filter-chip
        v-for="(selection, index) in debtorSapNumberSelection"
        :key="'debtorSapNumber' + selection"
        v-model="debtorSapNumberSelection"
        :index="index"
      >
        {{ $t('actionLog.debtorSapNumber') }}: {{ selection }}
      </active-filter-chip>
      <active-filter-chip
        v-for="(selection, index) in voucherSapNumberSelection"
        :key="'voucherSapNumber' + selection"
        v-model="voucherSapNumberSelection"
        :index="index"
      >
        {{ $t('actionLog.voucherSapNumber') }}: {{ selection }}
      </active-filter-chip>
      <active-filter-chip
        v-for="(selection, index) in voucherReferenceNumberSelection"
        :key="'voucherReferenceNumber' + selection"
        v-model="voucherReferenceNumberSelection"
        :index="index"
      >
        {{ $t('actionLog.voucherReferenceNumber') }}: {{ selection }}
      </active-filter-chip>
      <active-filter-chip
        v-for="(selection, index) in transactionKeySelection"
        :key="'transactionKey' + selection"
        v-model="transactionKeySelection"
        :index="index"
      >
        {{ $t('actionLog.transactionKey') }}: {{ selection }}
      </active-filter-chip>

      <app-text-btn
        v-if="hasActiveFilters"
        color="background-text"
        class="caption"
        title="resetFilter"
        @click="resetAllFilter"
      />
    </v-col>
  </v-row>
</template>

<script>
import ActionTypeSelect from '@/modules/Admin/components/Filter/ActionTypeSelect';
import ActiveFilterChip from '@/shared/components/ActiveFilterChip';
import CreditorListMixin from '@/modules/Admin/mixins/CreditorListMixin';
import DateSelect from '@/shared/DateSelect';
import ListFilterMixin from '@/mixins/ListFilterMixin';
import TextSearchFilter from '@/modules/Admin/components/Filter/TextSearchFilter';
import { NAMESPACE } from '@/modules/Admin/store';
import { mapState } from 'vuex';

export default {
  name: 'ActionLogFilter',

  mixins: [ListFilterMixin, CreditorListMixin],

  components: {
    ActionTypeSelect,
    DateSelect,
    TextSearchFilter,
    ActiveFilterChip
  },

  computed: mapState(NAMESPACE, ['actionTypes'])
};
</script>
