<template>
  <app-text-field
    v-model="input"
    :append-function="updateText"
    append-icon="$iconSearch"
    hide-supplement
    :label="label"
    single-line
    @blur="updateText"
    @keydown.enter="updateText"
  />
</template>

<script>
export default {
  name: 'TextSearchFilter',

  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    input: ''
  }),

  watch: {
    value: {
      handler() {
        if (this.input === this.valueAsString) {
          return;
        }

        this.input = this.valueAsString;
      },
      immediate: true
    }
  },

  computed: {
    valueAsString() {
      return this.value[0] ?? '';
    }
  },

  methods: {
    updateText() {
      if (this.input === this.valueAsString) {
        return;
      }

      this.$emit('input', this.input ? [this.input] : []);
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/styles/mixins/selectStyle';

::v-deep .v-text-field {
  @include SelectMixin;

  .v-input__slot {
    min-height: 45px !important;
  }

  label {
    top: 13px;
    font-size: 14px;
    color: var(--c-text);
  }
}
</style>
