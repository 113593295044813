<template>
  <app-data-table
    :headers="filterItemsWithFulfilledCondition(tableHeaders)"
    :items="formattedTableData"
    :loading="loadingList"
    :total-data-length="count"
  >
    <template #title>
      <div class="table-title__content">
        <div :class="classesTitleLeft">
          <div class="d-flex align-baseline">
            <span class="text-h5">{{ $tc('actionLog.actionsCounted', count) }}</span>
            <slot name="csv-download"></slot>
          </div>
        </div>
      </div>
    </template>

    <template #item="{ item }">
      <app-table-row
        :item="item"
        :highlighted="isShownInDetails(item.id)"
        @click="setDetailedParams(item.id)"
      >
        <app-table-data :sorted="isSorted(0)" :header="tableHeaders[0]">
          {{ item.creationDate | formatDateTime }}
        </app-table-data>
        <app-table-data :sorted="isSorted(1)" :header="tableHeaders[1]">
          {{ item.translatedActionType }}
        </app-table-data>
        <app-table-data :sorted="isSorted(2)" :header="tableHeaders[2]">
          {{ item.creditorSapNumber || '-' }}
        </app-table-data>
        <app-table-data :sorted="isSorted(3)" :header="tableHeaders[3]">
          {{ item.debtorSapNumber || '-' }}
        </app-table-data>
        <app-table-data :sorted="isSorted(4)" :header="tableHeaders[4]">
          {{ item.voucherSapNumber || '-' }}
        </app-table-data>
        <app-table-data :sorted="isSorted(5)" :header="tableHeaders[5]">
          {{ item.voucherReferenceNumber || '-' }}
        </app-table-data>
        <app-table-data :sorted="isSorted(6)" :header="tableHeaders[6]">
          {{ item.transactionKey || '-' }}
        </app-table-data>
      </app-table-row>
    </template>

    <template #no-data>
      <p>{{ $t('actionLog.noData') }}</p>
    </template>
  </app-data-table>
</template>

<script>
import TableMixin from '@/mixins/TableMixin';
import formatProduct from '@/helper/filter/formatProduct';
import { NAMESPACE } from '@/modules/Admin/store';
import { mapState } from 'vuex';

export default {
  name: 'ActionLogTable',

  mixins: [TableMixin],

  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    count: {
      type: Number,
      default: 0
    }
  },

  computed: {
    ...mapState(NAMESPACE, ['actionLogList', 'loadingList']),
    formattedTableData() {
      return JSON.parse(JSON.stringify(this.tableData)).map((entry) => ({
        ...entry,
        productTrans: formatProduct(entry.product)
      }));
    },
    tableHeaders() {
      return [
        { text: this.$t('date'), value: 'creationDate' },
        {
          text: this.$t('actionLog.action'),
          value: 'actionType',
          sortable: false,
          alignLeft: true
        },
        {
          text: this.$t('actionLog.creditorSapNumber'),
          value: 'filterCreditorSapNumber',
          sortable: false
        },
        {
          text: this.$t('actionLog.debtorSapNumber'),
          value: 'filterDebtorSapNumber',
          sortable: false
        },
        {
          text: this.$t('actionLog.voucherSapNumber'),
          value: 'filterVoucherSapNumber',
          sortable: false
        },
        {
          text: this.$t('actionLog.voucherReferenceNumber'),
          value: 'filterVoucherReference',
          sortable: false
        },
        {
          text: this.$t('actionLog.transactionKey'),
          value: 'filterTransactionKey',
          sortable: false
        }
      ];
    }
  }
};
</script>
