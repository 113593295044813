<template>
  <v-container fluid pa-5 pt-10>
    <h3 class="font-weight-bold mt-5">{{ $t('actionLog.details') }}</h3>
    <file-link-btn
      :loading="loading"
      append-icon
      class="mt-2"
      title="actionLog.downloadPdf"
      @click="downloadPdf"
    />

    <app-labeled-data
      class="mt-10"
      label="actionLog.action"
      :value="details.translatedActionType"
    />
    <app-labeled-data label="date">
      {{ details.creationDate | formatDateTime }}
    </app-labeled-data>
    <app-labeled-data label="product">
      {{ details.productType | formatProduct }}
    </app-labeled-data>

    <span class="d-block mt-10 my-1 subtitle text-uppercase">{{ $t('actionLog.sapNumbers') }}</span>
    <app-labeled-data
      label="actionLog.creditorSapNumber"
      :value="details.creditorSapNumber || '-'"
    />
    <app-labeled-data label="actionLog.debtorSapNumber" :value="details.debtorSapNumber || '-'" />
    <app-labeled-data label="actionLog.voucherSapNumber" :value="details.voucherSapNumber || '-'" />
    <app-labeled-data
      label="actionLog.voucherReferenceNumber"
      :value="details.voucherReferenceNumber || '-'"
    />
    <app-labeled-data label="actionLog.transactionKey" :value="details.transactionKey || '-'" />

    <span class="d-block mt-10 my-1 subtitle text-uppercase">{{ $t('actionLog.userData') }}</span>

    <app-labeled-data label="actionLog.userEmail" :value="details.userEmail || '-'" />
    <app-labeled-data label="actionLog.adminEmail" :value="details.adminEmail || '-'" />

    <span class="d-block mt-10 my-1 subtitle text-uppercase">{{ $t('actionLog.payload') }}</span>
    <pre class="action-log-details__payload">{{ details.payload }}</pre>

    <app-api-log :data="details.rawData" />
  </v-container>
</template>

<script>
import FileLinkBtn from '@/shared/components/FileLinkBtn.vue';
import OpenFileMixin from '@/mixins/OpenFileMixin';
import formatDateTime from '@/helper/filter/formatDateTime';
import { BASE_URL } from '@/plugins/axios';

export default {
  name: 'ActionLogDetails',

  mixins: [OpenFileMixin],

  components: { FileLinkBtn },

  props: {
    details: {
      type: Object,
      required: true
    }
  },

  methods: {
    async downloadPdf() {
      const downloadUrl = `${BASE_URL}/action-log/pdf-download/${this.details.id}`;
      const filename = `${this.details.creditorSapNumber}_${formatDateTime(
        this.details.creationDate
      )}_${this.details.translatedActionType}.pdf`;

      await this.openFile(downloadUrl, undefined, filename, true);
    }
  }
};
</script>

<style scoped lang="scss">
.action-log-details {
  &__payload {
    overflow-x: scroll;
  }
}
</style>
