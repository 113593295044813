<template>
  <v-container fluid pa-0>
    <actionLog-filter />
    <actionLog-table :table-data="actionLogList" :count="actionLogListCount">
      <template #csv-download>
        <csv-download
          :disabled="actionLogList.length === 0"
          :load-csv="loadCSV"
          :filename-prefix="$t('actionLog.title')"
        />
      </template>
    </actionLog-table>

    <app-side-drawer :show="!!detailedEntry">
      <ActionLogDetails v-if="detailedEntry" :details="detailedEntry" />
    </app-side-drawer>
  </v-container>
</template>

<script>
import ActionLogDetails from '@/modules/Admin/components/ActionLog/ActionLogDetails';
import ActionLogFilter from '@/modules/Admin/components/ActionLog/ActionLogFilter';
import ActionLogTable from '@/modules/Admin/components/ActionLog/ActionLogTable';
import AppSideDrawer from '@/shared/components/AppSideDrawer';
import CreditorListMixin from '@/modules/Admin/mixins/CreditorListMixin';
import CsvDownload from '@/shared/components/CsvDownload';
import ListFilterMixin from '@/mixins/ListFilterMixin';
import { NAMESPACE } from '@/modules/Admin/store';
import { QueryParam } from '@/statics/queryParam';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ActionLog',

  mixins: [CreditorListMixin, ListFilterMixin],

  components: {
    CsvDownload,
    ActionLogDetails,
    AppSideDrawer,
    ActionLogTable,
    ActionLogFilter
  },

  data: () => ({
    queryValues: [
      QueryParam.PAGE,
      QueryParam.ITEMS_PER_PAGE,
      QueryParam.SORT_BY,
      QueryParam.SORT_DESC,
      'creationDate',
      'actionType',
      'creditorSapNumber',
      'debtorSapNumber',
      'voucherSapNumber',
      'voucherReferenceNumber',
      'transactionKey'
    ],
    defaultSorting: {
      sortBy: ['creationDate'],
      sortDesc: [true]
    }
  }),

  computed: {
    ...mapState(NAMESPACE, ['actionLogList', 'actionLogListCount', 'actionTypes']),
    detailedEntry() {
      const entryId = this.$route.params.id;

      if (!entryId) {
        return null;
      }

      return this.actionLogList.find((entry) => entry.id === this.$route.params.id) ?? null;
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchActionLogList', 'fetchActionTypes', 'fetchActionLogCSV']),

    loadActionLogList(query = this.defaultQuery) {
      this.fetchActionLogList({
        page: query[QueryParam.PAGE],
        rowsPerPage: query[QueryParam.ITEMS_PER_PAGE],
        filters: this.buildFilters(),
        sorting: this.buildSorting(query)
      });
    },

    loadCSV() {
      return this.fetchActionLogCSV({
        page: 1,
        rowsPerPage: this.actionLogListCount ?? 0,
        filters: this.buildFilters(),
        sorting: this.buildSorting(this.$route.query)
      });
    },

    buildFilters() {
      let filters = {};

      if (this.creationDateSelection.length > 0) {
        filters.creationDate = {
          start: this.creationDateSelection[0].start / 1000 ?? null,
          end: this.creationDateSelection[0].end / 1000 ?? null
        };
      }

      if (this.actionTypeSelection.length > 0) {
        filters.actionType = this.actionTypeSelection.map((actionType) =>
          Object.keys(this.actionTypes).find((key) => this.actionTypes[key] === actionType)
        );
      }

      if (this.creditorSapNumberSelection.length > 0) {
        filters.filterCreditorSapNumber = this.creditorSapNumberSelection;
      }

      if (this.debtorSapNumberSelection.length > 0) {
        filters.filterDebtorSapNumber = this.debtorSapNumberSelection;
      }

      if (this.voucherSapNumberSelection.length > 0) {
        filters.filterVoucherSapNumber = this.voucherSapNumberSelection;
      }

      if (this.voucherReferenceNumberSelection.length > 0) {
        filters.filterVoucherReference = this.voucherReferenceNumberSelection;
      }

      if (this.transactionKeySelection.length > 0) {
        filters.filterTransactionKey = this.transactionKeySelection;
      }

      return Object.keys(filters).length > 0 ? filters : undefined;
    }
  },

  watch: {
    '$route.query': {
      deep: true,
      handler(query) {
        this.updateTableQueries(query, this.queryValues, this.loadActionLogList);
      }
    }
  },

  async created() {
    if (this.actionTypeSelection.length > 0) {
      await this.fetchActionTypes();
    } else {
      this.fetchActionTypes();
    }

    this.loadActionLogList();
  }
};
</script>
